import React from 'react'

import { CampaignPickerPage } from '../../components'

class MarketingPicker extends React.Component {
  render() {
    return <CampaignPickerPage />
  }
}

export default MarketingPicker
